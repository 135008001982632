.title-s1 {
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 3px;
  line-height: 1.0;

  @media screen and (max-width: 991px) {
    font-size: 1.55rem;
  }
}

.title-s2 {
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.0;
}
