#header {
	.header-content {
		.top-section {
			position: relative;
			padding: 12px 0;
			color: #ffffff;
			background-color: $csecondary;

			.container {
				>.row {
					justify-content: end;
					align-items: center;
				}

				.col-form {
					padding: 0;
					max-width: 425px;
					max-width: 425px;

					form {
						display: flex;
						flex-wrap: wrap;
						background-color: #f2f2f2;
						height: 34px;
						@include placeholder(#545454 ,500, normal, 1.0rem);

						.form-control {
							display: inline-flex;
							padding: 0.25rem 1.0rem;
							flex: 0 0 calc(100% - 44px);
							max-width: calc(100% - 44px);
							height: 34px;
							color: #424141;
							font-size: 1.0rem;
							font-weight: 500;
							border: 0;
							border-radius: 0;
							background-color: transparent;
						}

						.btn {
							@include flex-center-xy();
							width: 44px;
							height: 34px;
							color: #999b9e;
							font-size: 1.05rem;
							border: 0;
							border-radius: 0;
							background-color: transparent;
						}
					}
				}

				.col-buttons {
					// padding-top: 2px;
					max-width: 230px;
					line-height: 1.0;
					text-align: center;

					a {
						color: #ffffff;
						font-size: 1.25rem;
						letter-spacing: 2px;
					}
				}

				.col-social-networks {
					flex: 0 0 116px;
					max-width: 116px;
					line-height: 1.0;
					text-align: center;

					.btn-network {
						display: inline-block;
						padding: 3px 6px;
						margin: 0 4px;
						color: #ffffff;
						font-size: 1.30rem;

						&:first-child {
							margin-left: 0;
						}
						&:last-child {
							margin-right: 0;
						}

						.fa-apple {
							font-size: 118%;
						}

						&:hover {
							color: $csecondary;
						}
					}
				}
			}

			@media screen and (min-width: 992px) {
				.container {
					.col-buttons {
						padding-right: 0;
					}
				}
			}
			@media screen and (max-width: 991px) {
				.container {
					>.row {
						justify-content: center;
					}

					.col-form {
						margin-bottom: 7px;
						max-width: 100%;
						max-width: 100%;
					}
				}
			}
		}

		.menu-section {
			.navbar {
				background-color: #e0e0e0 !important;

				.navbar-brand {
					img {
						max-height: 75px;
					}
				}

				.navbar-toggler {
					padding: 0;
					color: $csecondary;
					font-size: 1.9rem;
					border: 0;
				}

				#nav-collapse {
					.navbar-nav {
						.nav-item {
							.nav-link {
								color: #000000;
							  font-size: 1.25rem;
								text-transform: uppercase;

								&:hover {
									color: #3d3c3c;
								}
							}
						}

						.dropdown {
							.dropdown-menu {
								border-radius: 0;
								border-color: $csecondary;
								background-color: $csecondary;

								li {
									.dropdown-item {
										color: #ffffff;

										&:hover, &:focus, &:active {
											color: #000000 !important;
											background-color: #e9ecef !important;
										}
									}
								}
							}

							&.show {
								.dropdown-toggle {
									color: #3d3c3c;
								}
							}
						}
					}
				}
			}

			@media screen and (min-width: 992px) {
				.navbar {
					#nav-collapse {
						.navbar-nav {
							.simple-item {
								margin-left: 24px;
								margin-right: 24px;
							}

							.icon-item {
								margin-left: 4px;
								margin-right: 4px;

								.nav-link {
									line-height: 1.0;

									.icon {
										font-size: 1.45rem;
									}
								}
							}
						}
					}
				}

				@media screen and (max-width: 1199px) {
					.navbar {
						.navbar-brand {
							img {
								max-height: 65px;
							}
						}

						#nav-collapse {
							.navbar-nav {
								.simple-item {
									margin-left: 18px;
    							margin-right: 18px;
								}
							}
						}
					}
				}
			}
			@media screen and (max-width: 991px) {
				.navbar {
					.navbar-brand {
						img {
							max-height: 45px;
						}
					}
				}
			}
		}
	}
}
