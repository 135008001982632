#blog-page {

  .articles-section {
    padding-top: 30px;
    padding-bottom: 35px;

    .box-top-s1 {
			.col-title {
				.title-s1 {
					span {
						font-size: 94%;
					}
				}
			}
		}

    .box-articles {
      margin-top: 10px;

      .col-article {
        margin-top: 14px;
        margin-bottom: 14px;
      }
    }

    @media screen and (max-width: 991px) {
      .box-articles {
        .col-article {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }

}

#blog-page{
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: 75vh;

  .main-section{
    .col-articles{
      .col-banner{
        a{
          display: block;
          width: 100%;

          img{
            width: 100%;
          }
        }
      }
    }

    .col-simple-articles{
      .col-article{
        position: relative;
        padding-top: 16px;
        padding-bottom: 16px;

        &:after{
          content: " ";
          position: absolute;
          bottom: 0;
          left: 15px;
          width: calc(100% - 30px);
          border-top: 1px solid #b6b6b6;
        }

        a{
          display: inline-block;
          color: #6a6a6a;
          font-size: 1.20rem;
          font-weight: 700;
          line-height: 1.2;
          text-transform: uppercase;
        }

        &:last-child{
          &:after{
            border-top: 0;
          }
        }
      }
    }

    @media screen and (min-width: 992px){
      @media screen and (max-width: 1499px){
        .col-articles{
          flex: 0 0 75%;
          max-width: 75%;
        }

        .col-simple-articles{
          flex: 0 0 25%;
          max-width: 25%;

          .col-article{
            padding-top: 12px;
            padding-bottom: 12px;

            a{
              font-size: 1.0rem;
            }
          }
        }
      }

      @media screen and (max-width: 1199px){
        .col-articles{
          flex: 0 0 100%;
          max-width: 100%;
        }

        .col-simple-articles{
          display: none;
        }
      }
    }

    @media screen and (max-width: 767px){
      .col-simple-articles{
        .col-article{
          padding-top: 10px;
          padding-bottom: 10px;

          a{
            font-size: 1.00rem;
            text-align: justify;
          }
        }
      }
    }
  }

}

#article-page{
  padding-top: 30px;
  padding-bottom: 30px;

  .main-section{
    .col-content{
      .box-content{
        display: inline-block;
        width: 100%;
        text-align: justify;
        line-height: 1.2;

        p{
          margin-bottom: 15px;

          &:last-child{
            margin-bottom: 0;
          }
        }

        img{
          max-width: 100%;
        }

        a{
          color: $cprimary;
        }

        ul,ol{
          padding-left: 15px;
        }
      }
    }

    .col-image{
      .image{
        display: block;

        img{
          width: 100%;
        }
      }
    }
  }
}
