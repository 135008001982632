#home-page {
	min-height: 70vh;

	.box-top-s1 {
		align-items: center;

		.col-title {
			.title-s1 {}
		}

		.col-btns {
			text-align: right;
			font-size: 1.55rem;
			line-height: 1.1;

			a {
				font-weight: 500;
				letter-spacing: 2px;

				&.txt-blue {
					color: #0b4b94;
				}
				&.txt-gray {
					color: #737373;
				}
			}
		}

		@media screen and (max-width: 1399px) {
			.col-title {
				.title-s1 {
					font-size: 1.70rem;
				}
			}

			.col-btns {
				font-size: 1.35rem;
			}
		}
		@media screen and (max-width: 991px) {
			.col-btns {
				font-size: 1.25rem;
			}
		}
		@media screen and (max-width: 575px) {
			text-align: center;

			.col-title, .col-btns {
				text-align: center;
			}

			.col-btns {
				margin-top: 3px;
				font-size: 1.30rem;
			}
		}
	}

	.companies-section {
		position: relative;

		.col-company {
			background-color: #000000;

			.box-company {
				position: relative;
				display: block;
				background-color: #000000;

				.fake {
					width: 100%;
				}

				.box-short-info {
					position: absolute;
					@include flex-center-xy();
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					color: #ffffff;
					cursor: pointer;
					z-index: 5;

					.box-center {
						display: inline-block;
						width: 100%;
						text-align: center;

						.logo {
							max-width: 70%;
							filter: grayscale(1) brightness(30%);

							&.brickdream {
								max-width: 220px;
							}
						}

						.title {
							margin-top: 42px;
							margin-bottom: 14px;
							color: #504f4f;
							font-size: 1.65rem;
							font-weight: 500;
						  letter-spacing: 2px;
							text-transform: uppercase;
						}

						.box-description {
							position: relative;
							display: inline-block;
							width: 72%;
							overflow: hidden;

							.descr {
								font-size: 1.340rem;
							  font-weight: 300;
							  line-height: 1.25;
							}

							.btn-more {
								display: inline-block;
								padding: 10px 15px;
								margin-top: 26px;
								width: 100%;
								color: #ffffff;
							  font-size: 1.55rem;
							  letter-spacing: 2px;
								text-decoration: none !important;
								border: 2px solid #828282;

								&:hover {
									background-color: #828282;
								}
							}
						}
					}

					&:hover {
						.box-center {
							.logo {
								filter: grayscale(0) brightness(100%);
							}

							.title {
								color: #ffffff;
							}

							.box-description {
								* {
									transition: font-size .25s,
									margin .25s,
									padding .25s,
									opacity .5s .25s;
								}
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 1599px) {
			.col-company {
				.box-company {
					.box-short-info {
						.box-center {
							.logo {
								max-width: 65%;

								&.brickdream {
									max-width: 190px;
								}
							}

							.title {
								margin-top: 30px;
								font-size: 1.50rem;
							}

							.box-description {
								width: 65%;

								.descr {
									font-size: 1.20rem;
								}

								.btn-more {
									padding: 9px 15px;
									margin-top: 22px;
									width: 88%;
									font-size: 1.30rem;
								}
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 1399px) {
			.col-company {
				.box-company {
					.box-short-info {
						.box-center {
							.logo {
								max-width: 60%;

								&.brickdream {
									max-width: 160px;
								}
							}

							.title {
								margin-top: 24px;
								font-size: 1.35rem;
							}

							.box-description {
								width: 70%;

								.descr {
									font-size: 1.05rem;
								}

								.btn-more {
									padding: 8px 15px;
									margin-top: 22px;
									width: 88%;
									font-size: 1.00rem;
								}
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 1299px) {
			.col-company {
				.box-company {
					.box-short-info {
						.box-center {
							.logo {
								&.brickdream {
									max-width: 145px;
								}
							}

							.title {
								margin-bottom: 8px;
								font-size: 1.25rem;
							}

							.box-description {
								width: 84%;

								.descr {
									font-size: 1.0rem;
								}
							}
						}
					}
				}
			}
		}
		@media screen and (min-width: 992px) {
			.col-company {
				.box-company {
					.box-short-info {
						&:not(:hover) {
							.box-center {
								.box-description {
									.descr, .btn-more, * {
										font-size: 0;
										margin: 0;
										opacity: 0;
										padding: 0;
										/* fade out, then shrink */
										transition: opacity .25s,
										font-size .5s .25s,
										margin .5s .25s,
										padding .5s .25s !important;
									}
								}
							}
						}
					}
				}

				&:nth-child(2) {
					.box-company {
						.box-short-info {
							border-left: 2px solid #fbfbfb;
							border-right: 2px solid #fbfbfb;
						}
					}
				}
			}
		}
		@media screen and (max-width: 991px) {
			background-color: #000000;

			>.row {
				justify-content: center;
			}

			.col-company {
				&:nth-child(2) {
					.box-company {
						.box-short-info {
							border-top: 2px solid #fbfbfb;
							border-bottom: 2px solid #fbfbfb;
						}
					}
				}
			}
		}
		@media screen and (max-width: 575px) {
			.col-company {
				.box-company {
					.box-short-info {
						.box-center {
							.logo {
								max-width: 73%;

								&.brickdream {
									max-width: 175px;
								}
							}
						}
					}
				}
			}
		}
	}

	.brands-section {
		padding-top: 32px;
		padding-bottom: 30px;

		.box-logos {
			margin-top: 15px;

			.swiper {
				.swiper-slide {
					.box-logo {
						display: flex;
						align-items: center;
						justify-content: center;
						height: 120px;

						.img {
							display: inline-block;
							width: 100%;
							text-align: center;

							img {
								max-width: 100%;
								max-height: 115px;
							}
						}
					}
				}

				.swiper-button-prev, .swiper-button-next {
					opacity: 1;

					&:after {
						color: #d9d9d9;
						font-size: 2.3rem;
						font-weight: 700;
					}
				}
				.swiper-button-prev {
					left: 0;
				}
				.swiper-button-next {
					right: 0;
				}
			}
		}

		@media screen and (max-width: 575px) {
			padding-top: 26px;
  		padding-bottom: 26px;

			.box-logos {
				margin-top: 10px;
			}
		}
	}

	.about-us-section-st1 {}

	.blog-section {
		position: relative;
		display: block;
		padding: 40px 0;

		.box-top-s1 {
			.col-title {
				.title-s1 {
					span {
						font-size: 94%;
					}
				}
			}
		}

		.box-carusel {
			margin-top: 20px;

			.swiper {
				.swiper-slide {
					padding: 2px 2px 45px 2px;
				}

				.swiper-pagination {
					bottom: 0;

					.swiper-pagination-bullet {
						margin: 0 6px;
						width: 10px;
						height: 10px;
						opacity: 1;
						background-color: #291063;
					}

					.swiper-pagination-bullet-active {
						background-color: #5380ae;
					}
				}
			}

			.box-article-s1 {}
		}

		@media screen and (max-width: 767px) {
			.box-top-s1 {
				.col-title {
					.title-s1 {
						span {
							font-size: 88%;
						}
					}
				}
			}
		}
		@media screen and (max-width: 575px) {
			padding: 30px 0;

			.box-carusel {
				.box-article-s1 {}
			}

		}
	}

	.cv-section {
		position: relative;
		padding-top: 28px;
  	padding-bottom: 28px;
		width: 100%;
		color: #ffffff;
		text-align: center;
		background-color: $cprimary;

		h5 {
			font-size: 2.6rem;
		}

		._btn {
			display: inline-block;
			padding: 11px 36px 14px 36px;
			color: #ffffff;
			font-size: 1.6rem;
			font-weight: 500;
			text-decoration: none !important;
			letter-spacing: 2px;
			border: 3px solid #ffffff;
			border-radius: 10px;
			@include transition(250ms);

			&:hover {
				background-color: $cprimary2;
			}
		}

		@media screen and (max-width: 575px) {
			padding-top: 16px;
  		padding-bottom: 28px;

			h5 {
				font-size: 2.4rem;
			}

			._btn {
				font-size: 1.30rem;
			}
		}
	}

	.video-section {
		position: relative;
		padding-top: 28px;
		padding-bottom: 25px;
		background-color: #e3e3e3;
		text-align: center;

		video {
			width: 640px;
			height: 359px;
			border: 3px solid #181818;
			background-size: cover;
			background-position: center;
			background-image: url('../images/pages/home/invrengar-video-home.jpg');
		}

		@media screen and (max-width: 767px) {
			video {
				width: 100%;
				height: 290px;
			}
		}
		@media screen and (max-width: 575px) {
			video {
				width: 100%;
				height: auto;
			}
		}
	}
}
