#company-info-page {

  .main-section {
    position: relative;
    border-top: 8px solid $cprimary;
    min-height: 65vh;
    padding-top: 50px;

    &:before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 560px;
      background-color: $csecondary;
    }

    >.container {
      position: relative;
      z-index: 10;
    }

    .box-logo {
      display: block;
      height: 155px;
      text-align: center;

      img {
        max-width: 96%;
      }

      &.invrengar {
        img {
          max-height: 143px;
        }
      }

      &.brick-dream {
        height: 215px;

        img {
          max-height: 210px;
        }
      }
    }

    .box-media {
      display: block;
      margin-top: 30px;
      height: 550px;
      border: 1px solid #6392c8;
      background-color: $soft-gray;

      iframe, video {
        width: 100%;
        height: 100%;
      }

      video {
        object-fit: cover;
        // pointer-events: none;
        background-image: url('../images/pages/home/piedra-angular.jpg')
      }
    }

    .box-content {
      position: relative;
      display: block;
      padding-top: 20px;
      padding-bottom: 40px;

      .box-info {
        display: block;

        .p-lg {
          font-size: 1.25rem;
        }

        .p-md {
          font-size: 1.10rem;
        }
      }

      .box-title {
        .title-s1 {
          font-weight: 700;

          &.t-lg {
            font-size: 2.0rem;
          }

          &.t-md {
            font-size: 1.5rem;
          }
        }
      }

      .box-extra {
        margin-top: 10px;
        margin-bottom: 10px;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;

        .col-info {
          margin-top: 13px;
          margin-bottom: 13px;

          .box-info {
            position: relative;
            padding: 17px 22px;
            @include flex-center-xy();
            flex: 0 0 100%;
            max-width: 100%;
            height: 100%;
            color: #ffffff;
            cursor: pointer;
            border: 2px solid $csecondary;
            background-color: $csecondary;
            @include transition(250ms);

            .inside {
              display: inline-block;
              width: 100%;
              line-height: 1.20;
              font-size: 0.95rem;
              text-align: center;

              .title {
                margin-bottom: 7px;
                // min-height: 44px;
                color: #ffffff;
                font-size: 1.24rem;
                font-weight: 600;
                line-height: 1.15;
                text-align: center;
              }
            }

            &:hover {
              box-shadow: 0 0 12px rgba(116, 163, 216,0.8);
              border-color: #000000;
              background-color: $csecondary2;
            }
          }
        }
      }

      .box-extra-video {
        position: relative;

        video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 10;

          &.video-1 {
            background-image: url('../images/pages/home/piedra-angular-1.jpg')
          }
          &.video-2 {
            background-image: url('../images/pages/home/piedra-angular-2.jpg')
          }
          &.video-3 {
            background-image: url('../images/pages/home/piedra-angular-3.jpg')
          }
        }

        .img-fake {
          display: block;
          width: 100%;
        }
      }

      .box-gallery {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        .col-image {
          border: 2px solid #ffffff;

          .image {
            position: relative;
            width: 100%;
            height: 165px;
            background-color: $soft-gray;
            cursor: pointer;
            outline: 1px solid transparent;
            @include transition(150ms);

            &:before {
              content: " ";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(255,255,255,0.45);
              opacity: 0;
              @include transition(150ms);
            }

            &:after {
              content: "\f002";
              position: absolute;
              @include flex-center-xy();
              bottom: 17px;
              left: 17px;
              font-family: "Font Awesome 5 Pro";
              font-weight: 300;
              font-size: 2.7rem;
              color: rgba(255,255,255,0.7);
              width: calc(100% - 34px);
              height: calc(100% - 34px);
              background-color: rgba(116,163,216,0.7);
              opacity: 0;
              @include transition(150ms);
            }

            &:hover {
              outline-color: $csecondary;

              &:before, &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 1199px) {
      padding-top: 45px;

      &:before {
        height: 440px;
      }

      .box-logo {
        height: 120px;

        img {
          max-width: 90%;
        }

        &.invrengar {
          img {
            max-height: 106px;
          }
        }
      }

      .box-media {
        margin-top: 25px;
        height: 460px;
      }
    }
    @media screen and (max-width: 991px) {
      &:before {
        height: 370px;
      }

      .box-media {
        height: 350px;
      }

      .box-content {
        .box-info {
          .p-md {
            font-size: 1.05rem;
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      padding-top: 28px;

      &:before {
        height: 320px;
      }

      .box-logo {
        height: 90px;

        img {}

        &.invrengar {
          img {
            max-height: 80px;
          }
        }
        &.brick-dream {
          height: 180px;

          img {
            max-height: 170px;
          }
        }
      }

      .box-media {
        margin-top: 14px;
        height: 270px;
      }

      .box-content {
        .box-info {
          line-height: 1.3;

          .p-lg {
            font-size: 1.05rem;
          }
        }

        .box-extra {
          .col-info {
            margin-top: 8px;
            margin-bottom: 8px;
          }
        }
      }
    }
    @media screen and (max-width: 575px) {
      padding-top: 35px;

      &:before {
        height: 270px;
      }

      .box-logo {
        &.invrengar {
          img {
            max-height: 66px;
          }
        }
      }

      .box-media {
        height: 220px;
      }

      .box-content {
        .box-info {
          .p-md {
            font-size: 1.00rem;
          }
        }
      }
    }
  }

  .cv-section {
		position: relative;
    padding: 28px 15px;
		width: 100%;
		color: #ffffff;
		text-align: center;
		background-color: $csecondary;

		h5 {
			font-size: 2.40rem;
		}

		._btn {
			display: inline-block;
			padding: 9px 36px 10px 36px;
			color: #ffffff;
			font-size: 1.30rem;
			font-weight: 400;
			text-decoration: none !important;
      text-transform: uppercase;
			letter-spacing: 2px;
			border: 3px solid #ffffff;
			border-radius: 10px;
			@include transition(250ms);

			&:hover {
				background-color: $csecondary2;
			}
		}

		@media screen and (max-width: 767px) {
			padding-top: 16px;
  		padding-bottom: 28px;

			h5 {
				font-size: 1.90rem;
        line-height: 1.0;
			}

			._btn {
        padding: 11px 20px 11px 20px;
				font-size: 1.00rem;
        font-weight: 500;
        line-height: 1.25;
        border-width: 2px;
			}
		}
	}

}
