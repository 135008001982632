.custom-f-group-s1 {
  >div {
    @include placeholder(#9f9fa2, 400, normal, 1.2rem);

    .form-control, .custom-select, .custom-file-label {
      height: 51px;
      color: #757584;
      font-size: 1.2rem;
      font-weight: 400;
      border: 3px solid #d2d2d2;
      border-radius: 0;
      background-color: transparent !important;
    }

    .custom-file-label {
      &:after {
        height: 100%;
      }
    }

    textarea {
      height: auto !important;
      resize: none;
    }
  }
}

.custom-f-group-s2 {
  >label ,>legend {
    color: #575756;
    font-size: 1.05rem;
    padding-bottom: 3px;
    letter-spacing: 2px;
  }

  >div {
    .form-control {
      padding: 0.6rem 0.75rem;
      height: inherit;
      color: #575756;
      border: 1px solid #575756;
      border-radius: 0;
      resize: none;
    }
  }

  .custom-file {
    .custom-file-label {
      border: 1px solid #575756;
      border-radius: 0;

      &:after {
        content: "Buscar"
      }
    }
  }
}


.custom-checkbox-s1 {
  padding-left: 2.8rem;

  .custom-control-label {
    color: #a6a6a6;
    cursor: pointer;
    font-size: 1.3rem;
    box-shadow: none !important;

    &:before, &:after {
      top: 0.15rem;
      left: -2.8rem;
      border-radius: 0;
      width: 1.6rem;
      height: 1.6rem;
    }

    &:before {
      border: 2px solid #d2d2d2 !important;
    }
  }

  .custom-control-input {
    box-shadow: none !important;
  }

  .custom-control-input:checked ~ .custom-control-label {
    &:before {
      border-color: $cprimary;
      background-color: $cprimary;
    }
    &:after {
      background-color: $cprimary;
    }
  }

  &.st-2 {
    .custom-control-label {
      color: #000000;

      &:before, &:after {
        border-radius: 5px;
        border-color: #999b9e !important;
      }
    }

    .custom-control-input:checked ~ .custom-control-label {
      &:before {
        border-color: #474b51;
        background-color: #474b51;
      }
      &:after {
        background-color: #474b51;
      }
    }
  }
}

.custom-radio-s1 {
  .custom-radio {
    display: block;
    margin-bottom: 13px;

    &:last-child {
      margin-bottom: 0;
    }

    .custom-control-label {
      cursor: pointer;
      font-size: 1.30rem;
      line-height: 1.35;

      &:before, &:after {
        width: 1.35rem;
        height: 1.35rem;
      }

      &:before {
        border-width: 2px;
      }
    }

    .custom-control-input:checked ~ .custom-control-label{
      &:before {
        background-color: $csecondary;
        border-color: $csecondary;
      }
      &:after {
        display: none;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .custom-radio {
      .custom-control-label {
        font-size: 1.15rem;

        &:before, &:after {
          top: 0.1rem;
        }
      }
    }
  }
  @media screen and (max-width: 575px) {
    .custom-radio {
      .custom-control-label {
        font-size: 1.00rem;

        &:before, &:after {
          top: 0.0rem;
        }
      }
    }
  }
}
