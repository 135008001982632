#footer {
  .top-section {
    position: relative;
    padding: 30px 0;
    width: 100%;
    color: #ffffff;
    background-color: #000000;

    >.container {
      >.row {
        align-items: center;
      }
    }

    a {
      color: #ffffff;
    }

    .title {
      font-size: 1.20rem;
      font-weight: 700;
    }

    .address {
      display: inline-block;
      line-height: 1.3;
      font-size: 0.97rem;
    }

    .copyright {
      display: inline-block;
      font-size: 1.20rem;
      font-weight: 400;
    }

    .col-footer {
      font-size: 1.15rem;
      letter-spacing: 1px;
    }

    .col-left {
      .btn-logo {
        display: inline-block;

        img {
          max-height: 115px;
        }
      }
    }

    .col-right {
      form {
        display: flex;
        flex-wrap: wrap;

        .custom-f-group-s1 {
          margin-bottom: 0;
          flex: 0 0 calc(100% - 88px);
          max-width: calc(100% - 88px);

          >div {
            @include placeholder(#ffffff, 400, normal, 1.3rem);

            .form-control {
              padding: 0.375rem 4.4rem 0.375rem 1.05rem;
              height: 88px;
              color: #ffffff;
              font-size: 1.3rem;
              letter-spacing: 2px;
              border-right: 0;
              background-repeat: no-repeat;
              background-size: 36px 23px;
              background-position: right 24px center;
              background-image: url('../images/shared/letter.svg');
            }
          }
        }

        .btn-send {
          border-radius: 0;
          width: 88px;
          height: 88px;
          color: #000000;
          font-size: 1.2rem;
          font-weight: 600;
          border: 0;
          background-color: #d2d2d2;

          &:hover {
            background-color: #b9b4b4;
          }
        }
      }
    }

    @media screen and (max-width: 575px) {
      .col-footer {
        text-align: center;
      }

      .col-right {
        form {
          .custom-f-group-s1 {
            flex: 0 0 calc(100% - 62px);
            max-width: calc(100% - 62px);

            >div {
              @include placeholder(#ffffff, 400, normal, 1.00rem);

              .form-control {
                padding: 0.375rem 1.05rem 0.375rem 1.05rem;
                height: 74px;
                font-size: 1.00rem;
                background-image: none;
              }
            }
          }

          .btn-send {
            padding-right: 0;
            padding-left: 0;
            width: 62px;
            height: 74px;
            font-size: 0.95rem;
            font-weight: 700;
          }
        }
      }
    }
  }

  .regulations-section {
    padding-top: 28px;
    padding-bottom: 28px;

    >.row {
      align-items: center;
    }

    .col-text {
      margin-bottom: 20px;
      text-align: center;

      h6 {
        font-size: 1.35rem;
        font-weight: 600;
        letter-spacing: 1px;
      }
    }

    .col-image {
      text-align: center;

      img {
        max-width: 100%;
        max-height: 86px;
      }
    }

    @media screen and (max-width: 575px) {
      .col-text {
        h6 {
          font-size: 1.05rem;
        }
      }

      .col-image {
        img {
          max-height: 60px;
        }
      }
    }
  }
}
