.box-article-s1 {
  position: relative;
  display: block;
  padding: 7px;
  height: 100%;
  color: #000000;
  cursor: pointer;
  border: 1px solid #737373;
  outline: 1px solid transparent;
  text-decoration: none !important;
  background-color: #ffffff;

  &:hover {
    border-color: $csecondary;
    outline: 1px solid $csecondary;

    .box-image {
      opacity: 0.90;
    }

    .box-info {
      .title {
        color: $csecondary;
      }
    }
  }

  .box-image {
    display: block;
    height: 190px;
    background-color: $soft-gray;
  }

  .box-info {
    display: block;
    padding: 0 5px 5px 5px;
    margin-top: 10px;

    .title {
      margin-bottom: 8px;
      min-height: 54px;
      font-size: 1.55rem;
      letter-spacing: 1px;
      line-height: 1.1;
    }

    .descr {
      position: relative;
      height: 126px;
      color: #000000;
      font-size: 1.15rem;
      font-weight: 300;
      line-height: 1.2;
      text-align: justify;
      overflow: hidden;

      &:after {
        content: " ";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 15px;
        background: linear-gradient(0deg, rgba(255,255,255,1) 37%, rgba(255,255,255,0) 100%);
      }

      p {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }

    }
  }

  @media screen and (max-width: 991px) {
    .box-info {
      .descr {
        height: auto !important;
      }
    }
  }
  @media screen and (max-width: 575px) {
    .box-image {
      height: 140px;
    }

    .box-info {
      .title {
        font-size: 1.25rem;
      }

      .descr {
        height: 149px;
        font-size: 1.0rem;
      }
    }
  }
}
