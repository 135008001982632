.about-us-section-st1 {
	padding-top: 50px;
	padding-bottom: 45px;
	background-color: #f2f2f2;

	>.row {
		align-items: center;
	}

	.col-image {
		.image {
			margin-top: 24px;

			img {
				max-width: 95%;
			}
		}
	}

	.col-info {
		font-size: 1.40rem;
		text-align: justify;

		.box-buttons {
			.btn-contact {
				@include flex-center-xy();
				display: inline-flex;
				margin: 0 10px;
				width: calc(50% - 14px);
				height: 80px;
				letter-spacing: 2px;
				border: 3px solid #b9b9b9;
				text-decoration: none !important;

				&:first-child {
					margin-left: 0;
				}
				&:last-child {
					margin-right: 0;
				}

				&.b1 {
					color: $cprimary;

					&:hover {
						background-color: #b9b9b9;
					}
				}

				&.b2 {
					color: #ffffff;
					background-color: $cprimary;

					&:hover {
						background-color: $cprimary2;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1509px) {
		.col-info {
			font-size: 1.3rem;

			.btn-contact {
				width: 290px;
				height: 75px;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.col-image {
			text-align: center;
		}

		.col-info {
			margin-top: 20px;
			font-size: 1.2rem;
			text-align: justify;

			.box-buttons {
				text-align: center;

				.btn-contact {
					height: 65px;
				}
			}
		}
	}
}

#corporative-page {}
